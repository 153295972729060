
@import "../../index.scss";

$fontSize: 14px;
$buttonHeight: $fontSize * 1.5;

$buttonOffset: 0px;
.option-1 {
    position: absolute;
    color: white;
    z-index: 100;
    left: 90px;
    top: 380px;
    width: 290px;
    height: 90px;
    cursor: pointer;
}

.option-2 {
    position: absolute;
    color: white;
    z-index: 100;
    left: 90px;
    top: 540px;
    width: 290px;
    height: 90px;
    cursor: pointer;

}

.option-3 {
    position: absolute;
    color: white;
    z-index: 100;
    left: 90px;
    top: 710px;
    width: 290px;
    height: 90px;
    cursor: pointer;

}
.left-arrow {
    position: absolute;
    color: white;
    z-index: 100;
    left: 0px;
    top: 420px;
    width: 90px;
    height: 90px;
}
.right-arrow {
    position: absolute;
    color: white;
    z-index: 100;
    right: 0px;
    top: 420px;
    width: 90px;
    height: 90px;
}
.multipage--transform-container {
    position: relative;
    top: 100px;
    height: calc( 100% - $coreMenuHeight );
    width: 100%;
}

.multipage--bkg {
    position: relative;
}


.multipage--frg {
    position: absolute;
    left: 0;
    top: 52px;
}


.multipage--menu {
    position: absolute;
    // top: -1px;
    width: 100%;
    height: $secondaryMenuHeight;
    background: $primaryTextColour;
    


    // &:before {
    //     position: absolute;
    //     top: 0px;
    //     left: -10px;
    //     width: 12px;
    //     height: 100%;
    //     background: $primaryTextColour;
    //     content: '';
    // }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-evenly;
        list-style-type: none;  // Add this line to remove bullets

        li {
            // display: inline-block;
            // position: relative;
        }
    }
}

.multipage--btn-container {
    // display: inline-block;
   
    margin-left: 0%;
    padding-top: calc( ($secondaryMenuHeight - $buttonHeight) / 2 ) + $buttonOffset;
    padding-bottom: calc( ($secondaryMenuHeight - $buttonHeight) / 2 ) - $buttonOffset;

    &:first-of-type {
        margin-left: 0px
    }
}

.multipage--btn {
    width: 100%;
    @include font-header;
    letter-spacing: 0.1em;
    font-size: $fontSize;
    font-weight: 500;
    text-transform: uppercase;
    color: black;
    opacity: 0.6;
    // transform: translate(-50%);
    img {
        position: absolute;
        left: 0;
        top: 0px;
    }

    &:hover {
        opacity: 1;
        width: 100%;
        border-bottom: 1px solid black;
        padding-bottom: 5px;
        margin-bottom: 37px;
        padding-left: 0px;
        padding-right: 0px;
    }   
}


    .multipage--btn__active {
        @extend .multipage--btn;
        opacity: 1;
        opacity: 1;
        width: 100%;
        border-bottom: 1px solid black;
        padding-bottom: 5px;
        margin-bottom: 37px;
        padding-left: 0px;
        padding-right: 0px;
    }