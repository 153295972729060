@import "../../index.scss";

.vision--button__left-half {
    position: absolute;
    left: 200px;
    top: 430px;
    width: 550px;
    height: 300px;
}
.vision--button__right-half {
    position: absolute;
    left: 1140px;
    top: 430px;
    width: 550px;
    height: 300px;
}

.vision--button__left-third {
    position: absolute;
    left: 110px;
    top: 430px;
    width: 440px;
    height: 300px;
}
.vision--button__center-third {
    position: absolute;
    left: 740px;
    top: 430px;
    width: 440px;
    height: 300px;
}
.vision--button__right-third {
    position: absolute;
    left: 1380px;
    top: 430px;
    width: 440px;
    height: 300px;
}

.image-bg {
    margin-top: 100px;
}