
@import "../../index.scss";

$fontSize: 14px;

$navFontSize: 15px;
$logoOffset: -1px;
$logoHeight: 50px;

$linkHeight: $fontSize * 1.5;
$linkOffset: 0px;
$secondaryLinkOffset: 1px;

$linkPaddingTop: calc( ($coreMenuHeight - $linkHeight) / 2 ) + $linkOffset;
$linkPaddingBottom: calc( ($coreMenuHeight - $linkHeight) / 2 ) - $linkOffset;

$logoPaddingTop: calc( ($coreMenuHeight - $logoHeight) / 2 ) + $logoOffset;
$logoPaddingBottom: calc( ($coreMenuHeight - $logoHeight) / 2 ) - $logoOffset;

.header {
    z-index: $coreMenuZIndex;
    position: fixed;
    top: 0;
    width: 100%;
    height: $coreMenuHeight - 2;
    &--nav {
        position: relative;
        background: $bkgColour;
        // background: $bkgGradient;
        // box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

        // &::before {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        //     border-bottom: 1px solid black;
        // }

        > ul {
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            > li {
                position: relative;

                width: 100%;

                &:first-of-type {
                    margin-left: $menuEdgeSpacing;
                }

                // &:hover {
                //     border-bottom: 1px solid black;
                //     padding-bottom: 5px;
                //     margin-bottom: 37px;
                // }
            
                // > * {
                //     transform: translateX(-10%);
                // }
            }
        }
    }

    &--link {
        display: inline-block;
        padding: #{ $linkPaddingTop } 25px #{ $linkPaddingBottom };

        color: black;
        @include font-header;
        font-size: $navFontSize;
        letter-spacing: 0.3em;
        line-height: 1.5;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;

        // transition: all 0.3s ease-out;
        opacity: 0.65;
        
        &:hover {
            opacity: 1;
            border-bottom: 1px solid black;
            display: inline-block;
            padding: #{ $linkPaddingTop } 0px 5px;
            
            color: black;
            @include font-header;
            font-size: $navFontSize;
            letter-spacing: 0.3em;
            // line-height: -2;
            margin-left: 30px;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            // width: 100%
            // padding-left: 0px;
            // padding-right: 0px;
      

        }   
    }


        &--link__selected {
            @extend .header--link;
            border-bottom: 1px solid black;
            padding-bottom: 5px;
            margin-bottom: 37px;
            padding-left: 0px;
            padding-right: 0px;
            opacity: 1;

            margin-left: 30px;
            margin-right: 0px;

        }

    &--link-logo { 
        @extend .header--link;
        padding-top: #{ $logoPaddingTop };
        padding-bottom: #{ $logoPaddingBottom };
        opacity: 1;
        
        img {
            max-height: $logoHeight;
        }
    }


    &--sub-menu-container {
        display: inline-block;
        
        // .header--link {
        //     padding-bottom: calc($linkPaddingBottom + 2px);
        // }
    }

        &--sub-menu {
            display: none; //displayed with JS
            position: absolute;
            left: 50%;
            top: 100%;
            width: 200px;
            padding: 10px;
            transform: translateX(-50%);
            background: $bkgColour;
            border-radius: 0px 0px 12px 12px;
            overflow: hidden;
            > ul {
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }

            &--sub-link {
                @extend .header--link;

                
 
                text-transform: none;
                height: auto;
                padding: 25px 20px 20px;
                width: 100%;


                &__selected {
                    @extend .header--link__selected;
                    text-transform: none;
                    height: auto;
                    padding: 25px 20px 20px;
                    width: 100%;
                    
                }
            }
}

.secondary-menu {
    position: absolute;
    top: $coreMenuHeight;
    width: 100%;
    height: $secondaryMenuHeight;
    background: $primaryTextColour;
    z-index: -1;
    ul {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    &--link-container {
        display: inline-block;
        width: 0;
        margin-left: $menuSpacing;
        padding-top: calc( ($secondaryMenuHeight - $linkHeight) / 2 ) + $secondaryLinkOffset;
        padding-bottom: calc( ($secondaryMenuHeight - $linkHeight) / 2 ) - $secondaryLinkOffset;
    
        &:first-of-type {
            margin-left: $menuEdgeSpacing;
        }
    }

    &--link {
        display: inline-block;

        @include font-primary;
        font-size: $fontSize;
        font-weight: 500;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        white-space: nowrap;

        opacity: 0.6;
        transform: translate(-50%);
        
        img {
            position: absolute;
            left: 0;
            top: 0px;
        }
    
        &:hover {
            opacity: 1;
        }
    }

        &--link__selected {
            opacity: 1;
        }
}

.header-logo {
    margin-left: -100px;
}